export interface BusinessType {
  id: BusinessTypes;
  name: string;
}

export enum BusinessTypes {
  SoleProprietorship = 1,
  PublicCorporation = 2,
  PrivateCorporation = 3,
  SingleMemberLLC = 4,
  MultiMemberLLC = 5,
  PublicPartnership = 6,
  PrivatePartnership = 7,
}
